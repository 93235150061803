.timeline-table {
    .MuiTablePagination-root {
        display: none;
    }

    .MuiDataGrid-footerContainer {
        display: none !important;
    }

    .action-cell {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
    }

    .link-text {
        color: #545cd8;
        cursor: pointer;
        text-decoration: underline;
    }

    .cursor-pointer {
        cursor: pointer;
    }

    .text-bold {
        font-weight: bold !important;
    }

    .MuiDataGrid-columnHeaderTitle {
        font-weight: bold !important;
    }

    .trash-icon {
        color: red;
    }
}
