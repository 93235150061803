.marketplace-opportunities-tab {
    margin-top: 15px;

    .header-title {
        color: black;
        font-family: 'SegoeUI-Bold';
        font-size: 16px;
    }

    .page-header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .opportunity-id-header {
        width: 10% !important;
    }
    
    .deal-stage-header {
        width: 15% !important;
    }
    
    .type-header {
        width: 10% !important;
    }
    
    .fte-dvm-header {
        width: 10% !important;
    }
    
    .listing-date-header {
        width: 10% !important;
    }
    
    .location-header {
        width: 10% !important;
    }
    
    .status-header {
        width: 10% !important;
    }
    
    .next-deadline-header {
        width: 10% !important;
    }
    
    .actions-column-header {
        width: 15% !important;
    }

    .buttons-wrapper{
        display: flex;

        .btn {
            margin-right: 1rem;
        }
    }
}
