.timeline-editor {
    .button-container {
        display: flex;
        justify-content: flex-end;
    }

    .input-height {
        height: 38px !important;
    }

    .delete-button {
        color: #d32f2f;
    }

    .flex-container {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    .btn-cancel {
        height: auto;
        width: auto;
        background-color: #fff;
        color: #919191;
        border-color: #919191;
    }

    .text-bold {
        font-weight: bold !important;
    }

    .cursor-pointer {
        cursor: pointer;
    }

    @media (max-width: 767px) {
        .hide-on-small {
            display: none;
        }

        .change-on-small {
            margin-bottom: 10px;
        }

        .add-event-btn {
            width: 100%;
        }
    }

    @media (max-width: 992px) {
        .buttons-container {
            .ButtonLoader-Div {
                width: 100%;
            }

            .btn {
                width: 100%;
            }
        }
    }
}