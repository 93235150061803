.gantt-chart-data-row {
    .gantt-chart-data-task-text {
        height: 30px;
        align-content: center;
        font-size: 12px;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0 5px 0 5px;
    }

    .row-number {
        left: 0px;
        height: 25px;
        place-content: center;
        top: 2px;
        color: grey;
        font-size: 12px;
        position: absolute;
        text-align: right;
        border-radius: 1px;
        padding-right: 10px;
    }

    .row-header {
        color: black;
        font-weight: bold;
        align-content: center;
        font-size: 12px;
        margin-left: 0.125em;
        height: 100%;
        padding-left: 10px;
    }
}
